import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import RscHbCardArticle from "~components/molecules/rsc_hb_card/rsc_hb_card_article/rsc_hb_card_article";
import RscHbCardArticleFeatured from "~components/molecules/rsc_hb_card/rsc_hb_card_article_featured/rsc_hb_card_article_featured";
import RscHbInPageContactForm from "~components/molecules/rsc_hb_in_page_contact_form/rsc_hb_in_page_contact_form";
import RscHbPageHeader from "~components/molecules/rsc_hb_page_header/rsc_hb_page_header";
import RscHbPageWrapper from "~components/molecules/rsc_hb_page_wrapper/rsc_hb_page_wrapper";
import RscHbPaginatedList from "~components/molecules/rsc_hb_paginated_list/rsc_hb_paginated_list";
import usePageData from "~hooks/use_page_data/use_page_data";
import {
  RESOURCE_HUB_SECTION,
  STORYBLOK_ENTRY,
  STORYBLOK_ENTRY_ARRAY,
} from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import RscHbSectionInner from "../template_resource_hub/components/rsc_hb_section_inner/rsc_hb_section_inner";
import getBlogListCtaComponent from "./helper_functions/get_blog_list_cta_component";

const GRID_SPLIT_ARTICLES = { XXL: "3", XL: "2", XS: "1" };

export default function TemplateListBlogs(props) {
  const { story, pageContext, entryAssets } = usePageData(props);

  const {
    breadcrumbsItems,
    categoryTitle,
    categorySubtitle,
    contactForm,
    currentPage,
    itemsPerPage,
    lang,
    pageCount,
    pagePath,
    subNavData,
    sections,
    currentCategorySlug,
    resourceHubCtaConfig,
    UIStrings,
    navigationConfig,
  } = pageContext;

  const { paginationSectionObject, featuredItem } = sections;

  const {
    title,
    subtitle,
    featuredItemTitle,
    allItemsTitle,
    categoryItemsTitle,
  } = story;

  const isAtTopLevel = currentPage === 1;

  const ctaComponent = getBlogListCtaComponent({
    currentCategorySlug,
    resourceHubCtaConfig,
  });

  return (
    <TemplateGlobalEntry
      subNavData={subNavData}
      entryAssets={entryAssets}
      {...props}
      headerColor="blue"
      pagePath={pagePath}
      programmaticPageTitle={categoryTitle || title}
      isListPage
    >
      <RscHbPageWrapper
        items={subNavData}
        lang={lang}
        level1ActiveItem="categories"
        level2ActiveItem={currentCategorySlug || "blog"}
        navigationConfig={navigationConfig}
      >
        <RscHbPageHeader
          breadcrumbsItems={breadcrumbsItems}
          title={categoryTitle || title}
          description={categorySubtitle || subtitle}
        />

        {/* ————— FEATURED BLOG ————— */}
        {isAtTopLevel && featuredItem?.items?.length > 0 && (
          <RscHbSectionInner title={featuredItemTitle}>
            <RscHbCardArticleFeatured {...featuredItem.items[0]} />
          </RscHbSectionInner>
        )}

        {/* ————— ALL BLOGS SECTION ————— */}
        <RscHbPaginatedList
          allItemsTitle={allItemsTitle}
          basePath={pagePath}
          categoryItemsTitle={categoryItemsTitle}
          categoryTitle={categoryTitle}
          contentCardComponent={RscHbCardArticle}
          ctaComponent={ctaComponent}
          currentPage={currentPage}
          gridSplit={GRID_SPLIT_ARTICLES}
          items={paginationSectionObject.items}
          itemsPerPage={itemsPerPage}
          pageCount={pageCount}
          UIStrings={UIStrings}
        />
      </RscHbPageWrapper>
      <RscHbInPageContactForm lang={lang} {...contactForm} />
    </TemplateGlobalEntry>
  );
}

TemplateListBlogs.propTypes = {
  data: PropTypes.shape({
    assets: PropTypes.shape({}),
    items: STORYBLOK_ENTRY_ARRAY,
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    sections: PropTypes.shape({
      paginationSectionObject: RESOURCE_HUB_SECTION,
      featuredItem: RESOURCE_HUB_SECTION,
    }),
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({})),
    UIStrings: PropTypes.shape({
      uiPageTitle: PropTypes.string,
    }),
    resourceHubCtaConfig: PropTypes.shape({}),
    categoryTitle: PropTypes.string,
    categorySubtitle: PropTypes.string,
    currentCategorySlug: PropTypes.string,
    contactForm: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    lang: PropTypes.string,
    pageCount: PropTypes.number,
    pagePath: PropTypes.string,
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

// TODO: get page specific metadata here
export const query = graphql`
  query listPageBlogsQuery(
    $id: String
    $langRegex: String
    $allCoverIDs: [String]
  ) {
    ...resourceHubStory
    ...global
    ...resourceHubCoverAssets
  }
`;
