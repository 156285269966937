import RscHbCtaLink from "~components/molecules/rsc_hb_cta/rsc_hb_cta_link/rsc_hb_cta_link";
import RscHbCtaNewsletter from "~components/molecules/rsc_hb_cta/rsc_hb_cta_newsletter/rsc_hb_cta_newsletter";

export default function getBlogListCtaComponent({
  currentCategorySlug,
  resourceHubCtaConfig,
}) {
  // ——————————————————————————————————————————————————————————————————————————————
  //      PEOPLE THEMED HIRING CTA
  // ——————————————————————————————————————————————————————————————————————————————

  if (
    resourceHubCtaConfig?.ctaHiringPeople &&
    (currentCategorySlug === "culture" ||
      currentCategorySlug === "announcements")
  ) {
    return () => {
      return RscHbCtaLink({
        ...resourceHubCtaConfig.ctaHiringPeople,
      });
    };
  }

  // ——————————————————————————————————————————————————————————————————————————————
  //      SIGN UP CTA
  // ——————————————————————————————————————————————————————————————————————————————

  // ToDo: factor these mappings out into an object of constants

  if (
    resourceHubCtaConfig?.ctaSignUp &&
    (currentCategorySlug === "payments" ||
      currentCategorySlug === "product" ||
      currentCategorySlug === "ecommerce" ||
      currentCategorySlug === "igaming" ||
      currentCategorySlug === "business-growth" ||
      currentCategorySlug === "wealth-tech" ||
      currentCategorySlug === "financial-services" ||
      currentCategorySlug === "open-banking")
  ) {
    return () => {
      return RscHbCtaLink({
        ...resourceHubCtaConfig.ctaSignUp,
      });
    };
  }

  // ——————————————————————————————————————————————————————————————————————————————
  //      ENGINEERING CTA
  // ——————————————————————————————————————————————————————————————————————————————

  if (
    resourceHubCtaConfig?.ctaHiringDevelopers &&
    currentCategorySlug === "engineering"
  ) {
    return () => {
      return RscHbCtaLink({
        ...resourceHubCtaConfig.ctaHiringDevelopers,
      });
    };
  }
  // ——————————————————————————————————————————————————————————————————————————————
  //      NEWSLETTER CTA
  // ——————————————————————————————————————————————————————————————————————————————

  if (resourceHubCtaConfig?.ctaNewsletter) {
    return () => {
      return RscHbCtaNewsletter({
        ...resourceHubCtaConfig.ctaNewsletter,
      });
    };
  }

  return null;
}
